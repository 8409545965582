import React from 'react';
import Banner from '../components/About/Banner';
import HeaderLandingPage from '../components/Headers/HeaderLandingPage';
import Footer from 'components/Landing/Footer';
import Mission from 'components/About/Mission';
import Chalenge from 'components/About/Chalenge';
import Solution from 'components/About/Solution';
import Tool from 'components/About/Tool';

const Info = () => {
  const pageStyle = {
    boxSizing: 'border-box',
    width: '100vw',
    maxWidth: '100%',
    overflowX: 'hidden' // Esconde qualquer conteúdo que ultrapasse horizontalmente
  };

  return (
    <div style={pageStyle}> {/* Aplicando estilo diretamente ao elemento raiz */}
      <HeaderLandingPage />  
      <Banner/>
      <Mission/>
      <Chalenge/>
      <Solution/>
      <Tool/>      
      <Footer/>      
    </div>
  );
};

export default Info;
