import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Mission = () => {
  // Estilos para a seção
  const sectionStyle = {
    padding: '4rem 2rem',
    color: 'black',
  };

  // Estilos para o vídeo incorporado
  const videoStyle = {
    width: '100%',
    height: '400px',
    border: 'none',
    borderRadius: '8px', // Opcional, para bordas arredondadas
  };

  return (
    <Container fluid style={sectionStyle} id="mission">
      <Row className="justify-content-center">
        <h1 className="display-3" style={{ fontSize: '3rem', fontFamily: 'sans-serif', color: 'black' }}>Nossa Missão</h1>
      </Row>

      {/* Linha contendo o vídeo incorporado */}
      <Row className="justify-content-center">
        <Col lg="8" md="10">
          <iframe
            src="https://player.vimeo.com/video/991081057" // URL para embutir o vídeo do Vimeo
            title="Vídeo Nossa Missão"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={videoStyle}
          />
        </Col>
      </Row>

      
    </Container>
  );
};

export default Mission;
